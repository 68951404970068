.blog-markdown {
  display: grid;
}

.blog-markdown img {
  width: 100%;
}

.blog-markdown p {
  margin-top: 1em;
  white-space: pre-wrap;
}

.blog-markdown blockquote {
  margin: 0;
  white-space: pre-wrap;
  font-style: italic;
  border-left: 5px solid #eee;
  padding: 0px 20px;
}

.blog-markdown h1 { 
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h3 { 
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h4 { 
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h5 { 
  display: block;
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.blog-markdown h6 { 
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.fb_dialog {
  /*bottom: 32px !important;*/
  /*right: 9pt !important;*/
  z-index: 9999 !important;
}

.fb_iframe_widget iframe {
  /*right: 0pt !important;*/
  z-index: 9999 !important;
}

.text-muted, .text-dark {
  color: #161c2d !important;
}

#topnav .navigation-menu > li > a {
  color: rgba(0,0,0,.85);
}

#Certificate-banners img {
  width: 100%;
  max-width: 6rem !important;
}
